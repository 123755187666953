<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col v-show="index > -1" cols="12" xs="12">
              <v-alert type="info" dense text class="text-body-2">
                환급,취소가 있는 경우 금액 등을 수정/삭제하여 실지출비용 형태로 관리하십시오.
              </v-alert>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-select
                v-model="form.gubun2"
                :items="costTypes"
                label="유형"
                dense
                menu-props="auto"
                @change="changeType"
              ></v-select>
            </v-col> -->
            <v-col cols="12" xs="12" sm="4" class="pt-0">
              <v-select
                v-model="form.gubun1"
                :items="costCates"
                label="항목"
                dense
                menu-props="auto"
                class="pt-5"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="2">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    class="ml-3 pt-8"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>비용항목의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <!-- <v-select
                v-model="form.status"
                :items="manageStatus"
                label="관리상태"
                dense
                menu-props="auto"
              ></v-select> -->
              <v-text-field
                v-model.trim="form.str3"
                label="납부처"
                maxlength="30"
                counter="30"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="5">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="7">
              <v-text-field
                ref="numStr2"
                v-model.trim="form.str2"
                v-validate="'required|max:12|is_not:0'"
                :error-messages="errors.collect('str2Cost')"
                data-vv-name="str2Cost"
                required
                label="*금액"
                maxlength="12"
                suffix="원"
                @keypress="preventComma"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                :label="cTitle1"
                maxlength="30"
                counter="30"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" xs="12" class="py-2">
              <v-text-field
                v-model.trim="article.name"
                label="부담자"
                prepend-icon="mdi-account-circle"
                readonly
                class="mr-2"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="6">
              <v-select
                v-model="form.gubun3"
                :items="selectedTypes"
                dense
                menu-props="auto"
                class="pt-3"
              ></v-select>
            </v-col> -->
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ article.name }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strDateFormat from '@/filters/strDateFormat'
import isNumber from '@/filters/isNumber'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 23, // 비용
      depth: 2,
      pId: 0,
      pType: 3, // 인명부
      pInfo: '', // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      isStar: 0,
      isSchedule: 1,
      subtype: 1, // 등록을 제외한 업무는 1로 세팅(등록은 자동입력데이터임)
      gubun1: '', // 카테고리관리 > 비용항목에서 가져온다(이자리는 항상 카테고리)
      gubun2: '지출', // 유형
      gubun3: '청구함', // 유형에 따른 구분 - '청구함'으로 픽스
      status: 2, // 관리상태 - '완료'로 픽스
      date1: '', // 일자
      str1: '', // 내용
      str2: '0', // 금액
      str3: '', // 납부처/환급처
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // 구분: 비용유형(gubun1) - 카테고리
    costCates: [],
    // 구분: 유형(gubun2)
    costTypes: [
      { text: '지출', value: '지출' },
      { text: '환급', value: '환급' }
    ],
    // 구분: 유형에 따른 항목변경(gubun3)
    selectedTypes: [],
    type1: [ // 지출인 경우
      { text: '청구안함', value: '청구안함' },
      { text: '청구함', value: '청구함' }
    ],
    type2: [ // 환급인 경우
      { text: '반환안함', value: '반환안함' },
      { text: '반환함', value: '반환함' }
    ],
    // cTitle1: '납부처', // 납부처/환급처 제목 변환
    // cTitle2: '부담자', // 비용부담자/반환대상자 제목 변환
    // 구분: 관리상태(status)
    manageStatus: [
      { text: '예정', value: 1 },
      { text: '완료', value: 2 },
      { text: '취소', value: 3 }
    ],
    // 구분: 수정 인덱스
    index: -1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str2Cost: '금액'
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    chkValidate: true,
    // 구분: 일자, 발행일
    date1Selector: false, // 일자 셀렉터
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 발행일 보이기 여부
    showReceipt: false
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    this.setCates('12').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.costCates = cates.map(c => c.gubun1)
        this.form.gubun1 = this.costCates[0] // 초기값 지정
      } else {
        this.costCates = []
      }
    })
  },

  watch: {
    // !![2021.3.15] str2 수정
    'form.str2': {
      handler: function (val, oldVal) {
        if (!val) {
          this.form.str2 = '0'
          this.$refs.numStr2.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.str2 = val.replace(/(^0+)/, '')
          }
          this.form.str2 = numberFormatR(this.form.str2)
          this.form.str2 = numberFormat(this.form.str2)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.str2)) {
            this.form.str2 = oldVal
            this.$refs.numStr2.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    isNumber, // 숫자만 인지 체크하는 필터
    numberFormat,
    numberFormatR,
    preventComma,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 3 // 부모 타입(인명부)
      this.form.pInfo = '' // 정보위치(부모인 인명부의 정보 중 보여줄 정보 이름)
      this.form.gubun1 = this.costCates[0]
      this.form.gubun2 = '지출' // 유형
      this.form.gubun3 = '청구함' // 유형에 따른 구분 - 중요: '청구함'으로 픽스
      this.form.status = 2 // 관리상태 - 중요: '완료'로 픽스
      this.form.isStar = 0
      this.form.isSchedule = 1 // 일정표시여부
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 일시 초기값(오늘)
      this.form.str1 = '' // 내용
      this.form.str2 = '0' // 금액
      this.form.str3 = '' // 납부처/환급처
      this.form.subtype = 1 // 이걸 해줘야 한다!
      this.form.parentInfo = ''

      this.selectedTypes = this.type1 // 지출로 맞춘다.

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터

        // 초기화
        await this.initDialog()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id

        // 중요:[2021.3.23] 부모정보를 parentInfo 에 넣는다
        this.form.parentInfo = this.article.name

        // 중요:[2021.3.19] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo) // this.article.name

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1
          this.form.gubun2 = obj.gubun2

          this.form.status = obj.status
          this.form.subtype = obj.subtype
          this.form.isStar = obj.isStar

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2.toString() // !! 계산된 금액(수정시 정수값을 문자열로 변환해야 한다.)
          this.form.str3 = obj.str3 // 납부처/환급처

          this.form.date1 = obj.date1.substr(0, 10) // 일자 처리

          // this.changeType() // 유형에 따라 변경
          this.form.gubun3 = obj.gubun3

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.txt1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // !! 한번더 - 금액에 대해 숫자만 남긴다
        // !![2021.3.15] 수정
        // this.form.str2 = this.form.str2.replace(/[^0-9]/g, '')

        // 일자 처리 - 시분초를 붙여준다.
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:18:01`

        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/client/setRelCost`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = 'success'

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          // const { data } = await this.$axios.get(`lawork/client/deleteLwcommon/${this.form.id}`)
          const params = {
            id: this.form.id,
            teamId: this.form.teamId,
            type: this.form.type,
            depth: this.form.depth,
            pId: this.form.pId,
            pType: this.form.pType,
            pInfo: this.form.parentInfo
          }
          const { data } = await this.$axios.post(`lawork/client/removeLwc`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed'

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 일자 셀렉트 선택시 저장 함수
    async saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // 인명부 상세페이지 바로가기
    async gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 client 디렉토리가 아닌 경우만 client/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('client') !== -1)) {
        this.$router.push(`/client/${this.article.id}`)
      }
    }
    // // 사용안함: 유형 셀렉트 변경
    // async changeType () {
    //   try {
    //     if (this.form.gubun2 === '지출') {
    //       this.selectedTypes = this.type1
    //       this.cTitle1 = '납부처'
    //       this.cTitle2 = '부담자'
    //     } else {
    //       // 환급
    //       this.selectedTypes = this.type2
    //       this.cTitle1 = '환급처'
    //       this.cTitle2 = '반환대상자'
    //     }
    //     this.form.gubun3 = this.selectedTypes[0].value
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // }
  }
}
</script>
